.nav{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background-color: #007BFF;
}
.nav h4{
    font-size: 25px;
    /* background-color: darkgreen; */
    color: white;
    padding: 12px;
}
.nav .icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.nav .icon svg{
    width: 100%;
}

.content {
    padding: 24px;
}
.content p{
    font-size: 16px;
    color: gray;
    font-weight: lighter;
}
.content .logo{
    width: 200px;
    margin: 12px;
    padding: 10px 14px;
    border: 2px solid lightgray;
    border-radius: 8px;
}
.content .logo img{
    width: 100%;
}
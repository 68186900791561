.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 350px; /* Adjust the width as needed */
    height: 100vh; /* Set the height to 100% of the viewport */
    background-color: #fff; /* Adjust the background color */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
    transition: transform 0.3s ease-in-out; /* Add a smooth transition effect */
    transform: translateX(100%); /* Hide the sidebar initially by translating it to the right */
    z-index: 100;
    overflow-y: scroll;
    /* background-color: rgba(0, 0, 0, 0.3); */
  }
  
  .sidebar.show-sidebar {
    transform: translateX(0); /* Show the sidebar by translating it to the left */
  }
  
  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3); /* Add a semi-transparent overlay */
    opacity: 0; /* Hide the overlay initially */
    transition: opacity 0.3s ease-in-out; /* Add a smooth transition effect */
  }
  
  .sidebar.show-sidebar + .sidebar-overlay {
    opacity: 1; /* Show the overlay when the sidebar is open */
  }
  
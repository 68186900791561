
form {
    max-width: 90%;
    margin: 5% auto;
    background: #f9f9f9;
    padding-bottom: 100px;
  }
  
  form div {
    margin-bottom: 1rem;
  }
  
  label {
    margin-bottom: .5em;
    color: #333333;
    display: block;
  }
  
  input, select, textarea {
    border: 1px solid #CCCCCC;
    padding: .5em;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
  }

  textarea{
    width: 100%;
    height: 150px;
  }
  
  button {
    padding: 0.7em;
    color: #fff;
    background-color: green;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  .btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close-btn{
     background-color: #007BFF;
  }
  .close-btn:hover {
    background-color: #0056b3;
  }
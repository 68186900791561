
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box; 
}

body{
  font-family: sans-serif;
}

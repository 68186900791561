#footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: black;
  padding: 1% 15%;
  color: white;
  font-size: 14px;
  /* position: absolute;
  left: 0;
  bottom: 0; */
  width: 100%;
  gap: 5%;
}

.footer-left {
  text-align: left;
}

.footer-right {
  text-align: right;
}

.footer-right a {
  color: white;
  text-decoration: none;
  margin: 0 5px;
}

.footer-right a:hover {
  text-decoration: underline;
}

@media (max-width: 800px) {
  .footer-left,
  .footer-right {
    text-align: center;
    width: 100%;
    margin: 2%;
  }
}

#main-page{
    min-height: 100vh;
    position: relative;
}

  
  p {
    margin-top: 25px;
    font-size: 20px;
  }
  
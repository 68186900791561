#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 1% 15%;
  color: white;
  flex-wrap: wrap;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-img img {
  width: 100px; /* Adjust the size as necessary */
  height: auto;
  margin-right: 1rem;
}

.info {
  text-align: right;
}

.info h1,
.info h3,
.info p {
  margin: 0;
}

.info h1 {
  font-size: 20px;
}

.info h3 {
  font-size: 16px;
}

.info p {
  font-size: 14px;
}

.info a {
  background-color: #4caf50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 5px 10px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Get it to display as a block */
  font-size: 14px; /* Increase font size */
  margin-top: 10px;
  cursor: pointer; /* Pointer/hand icon */
}

button:hover {
  background-color: darkgreen; /* Darker green on hover */
}

@media (max-width: 800px){
    #header{
        justify-content: center;
    }
    .info{
        text-align: center;
    }
}

.container {
    text-align: center;
    font-family: Arial, sans-serif;
    margin-top: 5%;
    padding: 0 5%;
    min-height: 70vh;
  }
  .button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 40px auto;
  }
  
  .container button {
    width: 120px;
    height: 80px;
    font-size: 30px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
  }
  
  .yes-button {
    background-color: green;
  }
  
  .no-button {
    background-color: red;
  }
  .no-button:hover{
    background-color: darkred;
  }

  @media (min-width: 1000px) {
    h1{
      font-size: 4rem;
    }
  }